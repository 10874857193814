@import "../../../../../_css/variables/variables.css";

.container {
    font-family: Inter, serif !important;
    max-width: 100%;
}

.container:before,
.container:after {
    display: none !important;
}

.select {
    height: var(--input-height);
    box-sizing: border-box;
    border: solid var(--color-border) 1px;
    display: flex;
    align-items: center;
    padding: 0 30px 0 12px !important;
    font-size: 16px;
    border-radius: var(--border-radius) !important;
}

.select:focus {
    background-color: transparent !important;
}

.icon {
    right: 6px;
}

.disabled {
    color: rgba(0, 0, 0, 0.87) !important;
}

.disabled .icon {
    display: none;
}
