@import "../../../_css/variables/variables.css";

.container,
.wrapper {
    height: 100px;
    background-color: var(--color-top-header-bg);
    transition-duration: 0.5s;
    transition-timing-function: ease;
}

.containerScrolling {
    height: 65px;
    border-bottom: 1px;
}

.wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: var(--zIndex-navBar);
    color: white;
}

.placeholder {
    height: 130px;
    color: white;
}
