:root {
    --color-primary: #ecee01;
    --color-primary-darker: #b6b000;
    --color-primary-lighter: #fdff30;
    --color-secondary: #023047;
    --color-secondary-darker: #012334;
    --color-secondary-lighter: #034566;
    --color-tertiary: #219ebc;
    --color-tertiary-darker: #187389;
    --color-tertiary-lighter: #26b8db;
    --color-quaternary: #fb8500;
    --color-quaternary-darker: #c76a00;
    --color-quaternary-lighter: #ff961f;
    --color-success: #0ead69;
    --color-canceled: #f36114;
    --color-font: #333;

    --color-text: #2b3f57;
    --color-text-fade: rgba(43, 63, 87, 0.74);
    --color-text-on-primary: var(--color-secondary);
    --color-text-on-secondary: var(--color-primary);

    --color-primary-bg: #1e1a33;
    --color-tertiary-bg: #fafbfd;
    --color-tertiary-text: #92a2ba;
    --color-secondary-bg: #f2f7fa;
    --color-firstLvl-bg: #fafafa;
    --color-firstLvl-bg-dark: #e0e0e0;
    --color-secondLvl-bg: #f5f6fa;
    --color-secondLvl-dark-bg: #dce0e9;
    --color-thirdLvl-bg: #fff;

    --color-warning: var(--color-tertiary);
    --color-error: #ff3e1d;
    --color-delete: #ff3e1d;

    --color-border: #e1e3e4;

    --color-header-bg: black;
    --color-header-font: #fff;
    --color-top-header-bg: var(--color-secondary-darker);
    --color-top-header-font: white;
    --color-menu-header-bg: white;
    --color-menu-header-font: #333;
    --color-footer-bg: var(--color-primary);
    --color-footer-font: var(--color-secondary);

    --color-orderStatus-inProgress: #ff6411;
    --color-orderStatus-closed: #2baf0e;
    --color-orderStatus-sav: #cb1212;
}
