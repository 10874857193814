.mobileContainer {
    display: none;
}

.ul,
.subMenu {
    list-style: none;
    display: flex;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 0;
}

.ul {
    li {
        a {
            color: var(--color-primary);
            text-transform: uppercase;
            font-weight: bold;
        }
        a:hover {
            color: var(--color-primary-darker);
        }
    }
}

.li {
    padding: 5px 20px;
}

.menuIcon {
    font-size: 28px !important;
}

.subMenuContainer {
    left: -50px;
}

.subMenu {
    padding: 5px 20px;
    height: 0;
    width: 300px;
    opacity: 0;
    background-color: var(--color-primary-bg);
}

.subMenuOpened {
    height: unset;
    animation: subGroupOpening 0.3s ease-in;
    opacity: 1;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.12);
    left: 20px;
}

@keyframes subGroupOpening {
    from {
        opacity: 0.1;
        transform: perspective(9cm) rotateX(-80deg);
    }
    60% {
        transform: perspective(9cm) rotateX(-20deg);
    }
    to {
        opacity: 1;
    }
}

@media (max-width: 1000px) {
    .ul {
        display: none;
    }

    .mobileContainer {
        display: flex;
        position: fixed;
        right: 15px;
        color: var(--color-primary);
    }

    /*.ul {
        li {
            a {
                span.fontWeight_bold {
                    font-size: 9px;
                }
            }
            a:hover {
                color: var(--color-primary-darker);
            }
        }
    }*/
}
