@font-face {
    font-family: "Inter";
    src: url(/static/assets/Inter-Medium.18b8c68e.ttf);
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Inter";
    src: url(/static/assets/Inter-SemiBold.c6588ec1.ttf);
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Inter";
    src: url(/static/assets/Inter-Bold.3e3cb905.ttf);
    font-weight: bold;
    font-style: normal;
}
