.container {
    border-radius: var(--border-radius);
    background-color: white;
    text-align: center;
    border: 2px solid white;
}

.container:hover {
    border: 2px solid var(--color-primary);
}

.selectedInvoice {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
