.container {
    border: 1px solid var(--color-secondLvl-bg);
    height: calc(100vh - 300px);
    overflow-y: auto;
}

@media (max-width: 950px) {
    .container {
        margin-bottom: 40px;
    }
}