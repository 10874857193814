.container {
    position: fixed;
    bottom: 20px;
    right: 40px;
    background-color: var(--color-primary);
    border-radius: 50%;
    padding: 20px;
    z-index: var(--zIndex-mesengerIcon);
    cursor: pointer;
}

.messengerIcon {
    color: black;
}

.container:hover {
    transform: scale(1.05);
}
