.container {
    border-radius: var(--border-radius);
    background-color: white;
}

.container:hover {
    -webkit-box-shadow: 0 8px 13px 2px rgba(0, 0, 0, 0.26);
    -moz-box-shadow: 0 8px 13px 2px rgba(0, 0, 0, 0.26);
    box-shadow: 0 8px 13px 2px rgba(0, 0, 0, 0.26);
    transform: scale(1.015);
    cursor: pointer;
}

.icons {
    display: flex;
    flex-direction: row;
}

@media (max-width: 1000px) {
    .container {
        display: flex;
        flex-direction: column;
    }
    .contentList{
        width: 100%;
    }

    .smallColumn,
    .column {
        width: 100% !important;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 6px;
        >div {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            text-align: center !important;
        }
    }
}

.column {
    width: 20%;
    padding: 6px;
    >div {
        text-align: left;
    }
}

.smallColumn {
    width: 10%;
    padding: 6px;
    >div {
        text-align: left;
    }
}