.toggleBlock {
    font-size: 13px;
    color: rgba(22, 51, 69, 0.8);
    cursor: pointer;
}

.toggle {
    height: 24px;
    width: 55px;
    border-radius: 12px;
    margin-right: 10px;
    background-color: #FFF;
    position: relative;
    border: solid 1px rgba(0, 0, 0, 0.1);
    transition: all 0.2s;
}

.toggle-label {
    position: absolute;
    right: 7px;
    top: 3px;
    font-size: 11px;
    font-weight: bold;
}

.toggle-handle {
    position: absolute;
    top: 2px;
    left: 3px;
    height: 17px;
    width: 17px;
    border-radius: 50%;
    background-color: #2E416B;
    transition: all 0.3s;
}

.toggle-handle:hover {
    background-color: #395286;
}

.toggleBlock:hover .toggle {
    background-color: rgba(40, 107, 255, 0.1);;
}

.toggleBlock_active {
    font-weight: bold;
    color: rgba(22, 51, 69);
}

.toggleBlock_active .toggle {
    background-color: #68CC9A !important;
}

.toggleBlock_active .toggle-handle {
    left: 33px;
}

.toggleBlock_active .toggle-label {
    left: 7px;
    right: auto;
}
