.container {
    position: relative;
    cursor: pointer;
    z-index: var(--zIndex-top-navBar);
    text-transform: uppercase;
    font-weight: bold;
}

.lang {
    text-transform: uppercase;
}

.languages {
    position: absolute;
    background-color: white;
    color: var(--color-top-header-bg);
    padding-top: 5px;
    padding-bottom: 5px;
    min-width: 160px;
    font-size: 14px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.12);
}

.language {
    padding: 3px 20px 3px 20px;
}

.language:hover {
    background-color: lightgrey;
}

@media (max-width: 1400px) {
    .languages {
        font-size: 12px;
        min-width: 120px;
        right: -24px;
    }
}

@media (max-width: 1000px) {
    .container {
        display: none;
    }
}
