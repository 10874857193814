:root {
    --color-primary: #ecee01;
    --color-primary-darker: #b6b000;
    --color-primary-lighter: #fdff30;
    --color-secondary: #023047;
    --color-secondary-darker: #012334;
    --color-secondary-lighter: #034566;
    --color-tertiary: #219ebc;
    --color-tertiary-darker: #187389;
    --color-tertiary-lighter: #26b8db;
    --color-quaternary: #fb8500;
    --color-quaternary-darker: #c76a00;
    --color-quaternary-lighter: #ff961f;
    --color-success: #0ead69;
    --color-canceled: #f36114;
    --color-font: #333;

    --color-text: #2b3f57;
    --color-text-fade: rgba(43, 63, 87, 0.74);
    --color-text-on-primary: var(--color-secondary);
    --color-text-on-secondary: var(--color-primary);

    --color-primary-bg: #1e1a33;
    --color-tertiary-bg: #fafbfd;
    --color-tertiary-text: #92a2ba;
    --color-secondary-bg: #f2f7fa;
    --color-firstLvl-bg: #fafafa;
    --color-firstLvl-bg-dark: #e0e0e0;
    --color-secondLvl-bg: #f5f6fa;
    --color-secondLvl-dark-bg: #dce0e9;
    --color-thirdLvl-bg: #fff;

    --color-warning: var(--color-tertiary);
    --color-error: #ff3e1d;
    --color-delete: #ff3e1d;

    --color-border: #e1e3e4;

    --color-header-bg: black;
    --color-header-font: #fff;
    --color-top-header-bg: var(--color-secondary-darker);
    --color-top-header-font: white;
    --color-menu-header-bg: white;
    --color-menu-header-font: #333;
    --color-footer-bg: var(--color-primary);
    --color-footer-font: var(--color-secondary);

    --color-orderStatus-inProgress: #ff6411;
    --color-orderStatus-closed: #2baf0e;
    --color-orderStatus-sav: #cb1212;
}
:root {
    --xs: 5px;
    --sm: 10px;
    --md: 20px;
    --lg: 50px;
    --xl: 100px;

    --bp-xl: 1200px;
    --bp-lg: 992px;
    --bp-md: 768px;
    --bp-sm: 576px;
    --bp-xs: 400px;

    --app-width: 1400px;

    --border-radius: 20px;
    --input-height: 36px;
}
:root {
    --zIndex-product-card: 700;
    --zIndex-navBar: 800;
    --zIndex-top-navBar: 900;
    --zIndex-mobileMenu: 10000;
    --zIndex-overlay: 11000;
}
html,
body {
    padding: 0;
    margin: 0;
}
button:focus {
    outline: 0;
}
button:hover {
    cursor: pointer;
}
a:hover {
    cursor: pointer;
}
table {
    padding: 0;
    border-spacing: 0;
}
input {
    border: none;

}
input
:focus {
    outline: 0;
}
input,
label,
select,
button,
textarea {
    margin: 0;
    border: 0;
    padding: 0;
    display: inline-block;
    vertical-align: middle;
    white-space: normal;
    background: none;
}
/* Remove the stupid outer glow in Webkit */
input:focus {
    outline: 0;
}
/* Box Sizing Reset
-----------------------------------------------*/
/* All of our custom controls should be what we expect them to be */
input,
textarea {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
/* These elements are usually rendered a certain way by the browser */
button,
input[type=reset],
input[type=button],
input[type=submit],
input[type=checkbox],
input[type=radio],
select {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
/* File Uploads
-----------------------------------------------*/
input[type=file] {

}
/* Search Input
-----------------------------------------------*/
/* Make webkit render the search input like a normal text field */
input[type=search] {
    -webkit-appearance: textfield;
    -webkit-box-sizing: border-box;
}
/* Turn off the recent search for webkit. It adds about 15px padding on the left */
::-webkit-search-decoration {
    display: none;
}
/* Buttons
-----------------------------------------------*/
button,
input[type="reset"],
input[type="button"],
input[type="submit"] {
    /* Fix IE7 display bug */
    overflow: visible;
    width: auto;
}
/* IE8 and FF freak out if this rule is within another selector */
::-webkit-file-upload-button {
    padding: 0;
    border: 0;
    background: none;
}
/* Textarea
-----------------------------------------------*/
textarea {
    /* Move the label to the top */
    vertical-align: top;

    /* Turn off scroll bars in IE unless needed */
    overflow: auto;
}
/* Selects
-----------------------------------------------*/
select {

}
select[multiple] {
    /* Move the label to the top */
    vertical-align: top;
}
.flex_center_center {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}
.flex_row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
}
.flex_rowReverse {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
            flex-direction: row-reverse;
}
.flex_row_center {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.flex_rowReverse_center {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
            flex-direction: row-reverse;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.flex_column {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}
.flex_column_center {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.flex-1 {
    -webkit-box-flex: 1;
        -ms-flex: 1 1;
            flex: 1 1;
}
.flex-2 {
    -webkit-box-flex: 2;
        -ms-flex: 2 1;
            flex: 2 1;
}
.flexWrap_wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
}
.justifyContent_center {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}
.justifyContent_flexEnd {
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
}
.justifyContent_spaceBetween {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
.justifyContent_spaceAround {
    -ms-flex-pack: distribute;
        justify-content: space-around;
}
.alignItems_center {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.alignItems_flexEnd {
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
}
.flexShrink_0 {
    -ms-flex-negative: 0;
        flex-shrink: 0;
}
a.btn,
.btn {
    color: white;
    font-weight: bold;
    padding: 10px 20px;
    background-color: #ecee01;
    background-color: var(--color-primary);
    border-radius: 3px 3px 3px 3px;
    cursor: pointer;
}
a.btn:focus,
a.btn:visited,
a.btn:active {
    color: white;
}
a.btn:hover,
.btn:hover {
    background-color: #b6b000;
    background-color: var(--color-primary-darker);
}
.btn_1 {
    font-weight: bold;
    text-transform: uppercase;
}
.p_0 {
    padding: 0 !important;
}
.p_5 {
    padding: 5px;
}
.p_10 {
    padding: 10px;
}
.p_20 {
    padding: 20px;
}
.p_25 {
    padding: 25px;
}
.p_40 {
    padding: 40px;
}
.pb_10 {
    padding-bottom: 10px;
}
.pb_15 {
    padding-bottom: 15px;
}
.pb_20 {
    padding-bottom: 20px;
}
.pt_0 {
    padding-top: 0;
}
.pt_5 {
    padding-top: 5px;
}
.pt_10 {
    padding-top: 10px;
}
.pl_10 {
    padding-left: 10px;
}
.pt_20 {
    padding-top: 20px;
}
.pt_40 {
    padding-top: 40px;
}
.pl_10 {
    padding-left: 10px;
}
.pl_20 {
    padding-left: 20px;
}
.pr_10 {
    padding-right: 10px;
}
.pr_40 {
    padding-right: 40px;
}
.pr_20 {
    padding-right: 20px;
}
.ph_5 {
    padding-left: 5px;
    padding-right: 5px;
}
.ph_10 {
    padding-left: 10px;
    padding-right: 10px;
}
.ph_20 {
    padding-left: 20px;
    padding-right: 20px;
}
.pv_2 {
    padding-top: 2px;
    padding-bottom: 2px;
}
.pv_5 {
    padding-top: 5px;
    padding-bottom: 5px;
}
.pv_10 {
    padding-top: 10px;
    padding-bottom: 10px;
}
.pv_20 {
    padding-top: 20px;
    padding-bottom: 20px;
}
.link {
    font-weight: 600;
    text-decoration: none;
    color: #023047;
    color: var(--color-secondary);
    -webkit-transition: color 0.3s;
    transition: color 0.3s;
}
.link:hover {
        color: #012334;
        color: var(--color-secondary-darker)
    }
a {
    color: #023047;
    color: var(--color-secondary);
    text-decoration: none;
}
a:visited, a:active, a:focus, a:link {
    color: #023047;
    color: var(--color-secondary);
}
a:hover {
    color: #034566;
    color: var(--color-secondary-lighter);
}
.m_auto {
    margin-left: auto;
    margin-right: auto;
}
.m_0 {
    margin: 0 !important;
}
.m_5 {
    margin: 5px !important;
}
.m_10 {
    margin: 10px !important;
}
.mh_10 {
    margin-left: 10px !important;
    margin-right: 10px !important;
}
.mh_20 {
    margin-left: 20px !important;
    margin-right: 20px !important;
}
.mh_30 {
    margin-left: 30px !important;
    margin-right: 30px !important;
}
.mb_1 {
    margin-bottom: 1px !important;
}
.mb_3 {
    margin-bottom: 3px !important;
}
.mb_5 {
    margin-bottom: 5px !important;
}
.mb_10 {
    margin-bottom: 10px !important;
}
.mb_15 {
    margin-bottom: 15px !important;
}
.mb_20 {
    margin-bottom: 20px !important;
}
.mb_25 {
    margin-bottom: 25px !important;
}
.mb_40 {
    margin-bottom: 40px !important;
}
.mb_60 {
    margin-bottom: 60px !important;
}
.ml_5 {
    margin-left: 5px !important;
}
.ml_10 {
    margin-left: 10px !important;
}
.ml_20 {
    margin-left: 20px !important;
}
.ml_25 {
    margin-left: 25px !important;
}
.ml_40 {
    margin-left: 40px !important;
}
.mr_3 {
    margin-right: 3px !important;
}
.mr_5 {
    margin-right: 5px !important;
}
.mr_10 {
    margin-right: 10px !important;
}
.mr_15 {
    margin-right: 15px !important;
}
.mr_20 {
    margin-right: 20px !important;
}
.mr_40 {
    margin-right: 40px !important;
}
.mr_60 {
    margin-right: 60px !important;
}
.mr_80 {
    margin-right: 80px !important;
}
.mt_1 {
    margin-top: 1px !important;
}
.mt_3 {
    margin-top: 3px !important;
}
.mt_5 {
    margin-top: 5px !important;
}
.mt_7 {
    margin-top: 7px !important;
}
.mt_10 {
    margin-top: 10px !important;
}
.mt_15 {
    margin-top: 15px !important;
}
.mt_20 {
    margin-top: 20px !important;
}
.mt_25 {
    margin-top: 25px !important;
}
.mt_30 {
    margin-top: 30px !important;
}
.mt_40 {
    margin-top: 40px !important;
}
.mt_60 {
    margin-top: 60px !important;
}
.mt_80 {
    margin-top: 80px !important;
}
.mv_5 {
    margin-top: 5px;
    margin-bottom: 5px;
}
.mv_10 {
    margin-top: 10px;
    margin-bottom: 10px;
}
.mv_20 {
    margin-top: 20px;
    margin-bottom: 20px;
}
.mv_30 {
    margin-top: 30px;
    margin-bottom: 30px;
}
.mv_60 {
    margin-top: 60px;
    margin-bottom: 60px;
}
.top_0 {
    top: 0;
}
.cursor_pointer {
    cursor: pointer;
}
.userSelect_none {
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}
.position_relative {
    position: relative;
}
.position_absolute {
    position: absolute;
}
.position_fixed {
    position: fixed;
}
.transform_rotateMinus90 {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
}
.transform_rotatePlus90 {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
}
.overflow_auto {
    overflow: auto;
}
.overflow_hidden {
    overflow: hidden;
}
.height_full {
    height: 100%;
}
.display_block {
    display: block;
}
.border_left {
    border-left: 1px;
    border-left-color: rgba(0, 0, 0, 0.2);
    border-left-style: solid;
}
.maxWidth_label {
    max-width: 200px;
}
.fontWeight_bold {
    font-weight: bold;
}
.text_normal {
    font-size: 16px;
    font-weight: normal;
}
.text_small {
    font-size: 12px;
}
.text_small_semiBold {
    font-size: 14px;
    font-weight: 600;
}
.text_discreet {
    font-size: 14px;
    opacity: 0.7;
}
.text_error {
    color: #ff3e1d !important;
    color: var(--color-error) !important;
}
.text_warning {
    color: #219ebc !important;
    color: var(--color-warning) !important;
}
.text_big {
    font-size: 18px;
}
.textAlign_right {
    text-align: right;
}
.textAlign_center {
    text-align: center;
}
.textOverflow_ellipsis {
    text-overflow: ellipsis;
}
.text_ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.lineHeight_1-1 {
    line-height: 1.1;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    padding: 0;
    margin-bottom: 20px;
}
.h1 {
    font-size: 48px;
    font-weight: bold;
}
.h2 {
    font-size: 32px;
    font-weight: bold;
}
@media (max-width: 780px) {
    .h1 {
        font-size: 32px;
    }

    .h2 {
        font-size: 24px;
    }
}
.fadeIn {
    -webkit-animation-name: fadeIn;
            animation-name: fadeIn;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
}
@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
* {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
}
html,
body {
    height: 100%;
}
body {
    font-family: "Inter", sans-serif;
    min-height: 100%;
    color: #2b3f57;
    color: var(--color-text);
    background-color: #fafafa;
    background-color: var(--color-firstLvl-bg);
    font-size: 17px;
    line-height: 1.6;
}
hr {
    background-color: #e0e0e0;
    background-color: var(--color-firstLvl-bg-dark);
    height: 1px;
    border: none;
}
textarea {
    white-space: pre-wrap;
}
p {
    margin: 0;
    line-height: 1.5;
    white-space: normal;
    font-size: 17px;
}
li {
    line-height: 2;
}
ul,
ol {
    margin: 0;
    white-space: normal;
}
img {
    max-width: 100%;
}
::-webkit-scrollbar {
    width: 10px;
    background-color: #f1f1f1;
}
::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border: solid 1px transparent;
}
.hidden {
    display: none;
}
.shadow {
    -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.12);
            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.12);
}
h1,
h2,
h3,
h4 {
    color: #023047;
    color: var(--color-secondary);
}
