.headerTitle,
.headerImgOver,
.headerImg {
    margin-top: -30px;
    height: 300px;
    width: 100%;
}

.headerImg {
    position: relative;
    object-fit: cover;
    object-position: center;
}

.headerImgOver {
    top: 0;
    background-color: rgba(1, 35, 52, 0.8);
    z-index: 1;
}

.headerTitle {
    top: 0;
    z-index: 2;
    color: white;
    font-weight: bold;
    font-size: 24px;
}

.cta,
.motorImgOver,
.motorImg {
    height: 140px;
    width: 100%;
}

.motorImg {
    object-position: center;
    object-fit: cover;
    position: relative;
}

.motorImgOver {
    background: linear-gradient(90deg, var(--color-secondary), rgba(2, 48, 71, 0.2));
    top: 0;
    z-index: 1;
}

.cta {
    top: 0;
    z-index: 1;
    color: white;
}

.overBtnText {
    font-weight: bold;
}

@media (max-width: 1000px) {
    .container {
        margin-top: 20px !important;
    }

    .motorContainer {
        margin-top: 0 !important;
    }

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 0 !important;
        width: 100%;
    }
}
