.container {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 30%;
    right: 0;
    z-index: var(--zIndex-mobileMenu);
    background-color: var(--color-tertiary-bg);
    padding: 20px;
    overflow: auto;
    transform: translateX(100vw);
    transition: all 0.4s ease-in-out;
}

.fullWidthContainer {
    left: 0;
}

.opened {
    display: block;
    transform: translateX(0);
}

.logo {
    display: inline-block;
    margin-top: 20px;
    margin-bottom: 40px;
}

.closeIcon {
    z-index: var(--zIndex-overlay);
    position: absolute !important;
    top: 10px;
    right: 20px;
}

.overlay {
    position: fixed;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 801;
    background-color: rgba(0, 0, 0, 0.4);
}
