.container {
    width: 100%;
    background-color: white;
    border-radius: var(--border-radius);
}

.input {
    min-width: 250px;
    input:read-only {
        border-color: transparent !important;
        cursor: text;
        border-width: 2px !important;
        padding-left: 0px;
    }
}

@media (max-width: 600px) {
    .input {
        margin-right: 0 !important;
    }
}
