.container {
    background-color: var(--color-footer-bg);
}

@media (max-width: 600px) {
    .content {
        flex-direction: column;
        text-align: center;
    }

    .separator {
        display: none;
    }

    .network {
        margin-top: 8px;
    }
}
