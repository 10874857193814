.container {
    transition: 0.4s all;
    position: fixed;
    bottom: 0;
    height: 0;
    width: 100vw;
    left: 0;
    right: 0;
    opacity: 0;
    background-color: white;
    -webkit-box-shadow: 0 -3px 8px 2px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 -3px 8px 2px rgba(0, 0, 0, 0.1);
    box-shadow: 0 -3px 8px 2px rgba(0, 0, 0, 0.1);
    z-index: var(--zIndex-mesengerSwipeUp);
}

.displayed {
    height: calc(33%);
    opacity: 1;
}

.close {
    -webkit-box-shadow: 0 -3px 8px 2px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 -3px 8px 2px rgba(0, 0, 0, 0.1);
    box-shadow: 0 -3px 8px 2px rgba(0, 0, 0, 0.1);
    background-color: white;
    border-radius: 50%;
    position: absolute;
    padding: 10px;
    right: 15px;
    top: -50px;
    cursor: pointer;
}

.close:hover {
    transform: scale(1.05);
}


@media (max-width: 600px) {

}