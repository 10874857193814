.container {
    width: 100%;
    background-color: white;
    border-radius: var(--border-radius);
    transition: all 0.2s;
    transform: translateX(0);
}

.reminder {
    font-size: 12px;
}

.hiddenOrder {
    transform: translateX(-100%);
}

.select {
    max-width: 50%;
}

.row {
    input:read-only {
        border-color: transparent !important;
        cursor: text;
        border-width: 2px !important;
        padding-left: 0px;
    }
}

@media (max-width: 780px) {
    .row {
        flex-direction: column;
        >div {
            margin-right: 0 !important;
            margin-bottom: 10px !important;
        }
    }

    .select {
        max-width: 100%;
    }
}
