.container {
    a:visited, a:active, a:focus, a:link {
        color: var(--color-primary-lighter);
    }
    a:hover {
        color: var(--color-secondary);
    }
}

.isOpened {
    transition: .2s;
    transform: rotate(-180deg);
}