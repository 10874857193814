.container {
    border-right: 1px solid var(--color-secondLvl-bg);
    height: calc(100vh - 230px);
}

.conversation {
    overflow-y: auto;
}

.cardContainer {
    border-radius: 40px;
    background-color: var(--color-secondLvl-bg);
    color: var(--color-font);
}

.mine {
    background-color: var(--color-primary);
    color: var(--color-secondary);
}

.input {
    border-top: 1px solid var(--color-secondLvl-bg);
    border-bottom: 1px solid var(--color-secondLvl-bg);
    bottom: 0;
}

.notRead {
    -webkit-box-shadow: 0 8px 13px 2px rgba(0, 0, 0, 0.26);
    -moz-box-shadow: 0 8px 13px 2px rgba(0, 0, 0, 0.26);
    box-shadow: 0 8px 13px 2px rgba(0, 0, 0, 0.26);
    transform: scale(1.015);
}
