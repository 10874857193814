.underline {
    background-color: var(--color-text);
    height: 8px;
    border-radius: 3px;
    width: 90px;
    border: none;
    margin-top: 10px;
    margin-bottom: 40px;
}

.centered {
    text-align: center;
}

.centered .underline {
    margin-left: auto;
    margin-right: auto;
}

.primary .underline {
    background-color: var(--color-primary);
}

.secondary .underline {
    background-color: var(--color-secondary);
}

.white .underline {
    background-color: var(--color-text-on-primary);
}

.collapse {

}
