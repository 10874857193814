.content,
.container {
    flex: 1;
    min-height: 400px;
    transition: 0.4s all;
    cursor: pointer;
    position: relative;
}

.container {
    color: #1E1A33;
    background-color: var(--color-secondary);
    display: flex;
    flex-direction: column;
}

.content {
    display: block;
    background-color: white;
    transform: translate(0, 0);
    transition: all 0.15s ease;
    position: relative;
    z-index: 10;
}

.image {
    height: 200px;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}

.date {
    background-color: white;
    padding: 5px;
}

.title {
    font-size: 18px;
    color: var(--color-primary);
    min-height: 75px;
    max-height: 75px;
    overflow: hidden;
    flex: 1;
}

.text {
    font-size: 18px;
    color: #151515;
    min-height: 150px;
    max-height: 150px;
    overflow: hidden;
    flex: 1;
}

.readMore {
    height: 60px;
    font-size: 18px;
    position: relative;
}

.textGradient {
    position: absolute;
    left: 0;
    right: 0;
    top: -30px;
    height: 30px;
    background-image: linear-gradient(to bottom, transparent, white);
}

.container:before,
.container:after {
    content: '';
    display: block;
    background-color: var(--color-secondary);
    width: 8px;
    height: 8px;
    position: absolute;
    transition: all 0.15s ease;
}

.container:before {
    top: 0;
    left: 0;
    transform-origin: top left;
    transform: rotate(-45deg) scale(0);
}

.container:after {
    right: 0;
    bottom: 0;
    transform-origin: bottom right;
    transform: rotate(45deg) scale(0);
}

.container:hover .content {
    transform: translate(6px, -6px);
}

.container:hover:before {
    transform: rotate(-45deg) scale(1);
}

.container:hover:after {
    transform: rotate(45deg) scale(1);
}