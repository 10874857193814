@import "../../../../../_css/variables/variables.css";

.outlined.root,
.container.root {
    font-family: "Inter", Gadget, sans-serif;
    font-size: 16px;
    padding: 10px 30px !important;
    text-align: center;
    background-size: 200% auto;
    text-transform: unset;
    border-radius: 20px !important;
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    cursor: pointer;
    display: inline-block;
    box-shadow: none;
}

.container.root {
    background-color: var(--color-primary);
}

.container:hover {
    box-shadow: none !important;
    background-position: right center;
    background-color: var(--color-primary-darker) !important;
}

.container.containedPrimary {
    color: var(--color-text-on-primary) !important;
    display: flex !important;
    justify-content: center !important;
}

.container.containedSecondary {
    color: var(--color-text-on-secondary) !important;
    display: flex !important;
    justify-content: center !important;
}

.container.label {
    font-family: "Inter", Gadget, sans-serif;
    font-size: 20px;
    font-weight: 600;
}

.container.containerForDelete {
    color: var(--color-delete);
}

.container.containerForDelete.contained {
    color: #fff;
    background-color: var(--color-delete);
}

.container.sizeSmall {
    min-height: auto;
}
