.section {

}

.picture {
    position: absolute;
    pointer-events: none;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.container {
    display: flex !important;
    z-index: 1;
    >* {
        z-index: 1;
    }
}

@media (max-width: 500px) {

    .section {
        height: 450px;
        padding-top: 60px !important;
        padding-bottom: 60px !important;
    }
}

@media (max-width: 350px) {
    .section {
        padding-top: 20px !important;
        padding-bottom: 20px !important;
    }
}
