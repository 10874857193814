h1.big {
    font-size: 48px;
}

h1.medium {
    font-size: 40px;
}

h1.small {
    font-size: 32px;
}

h2.big {
    font-size: 34px;
}

h2.medium {
    font-size: 32px;
}

h2.small {
    font-size: 28px;
}

h3.big {
    font-size: 28px;
}

h3.medium {
    font-size: 24px;
}

h3.small {
    font-size: 20px;
}
