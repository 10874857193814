.inProgress {
    background-color: var(--color-orderStatus-inProgress);
}

.closed {
    background-color: var(--color-orderStatus-closed);
}

.sav {
    background-color: var(--color-orderStatus-sav);
}

@media (max-width: 450px) {
    .inProgress {
        font-size: small;
    }
    .closed {
        font-size: small;
    }
    .sav {
        font-size: small;
    }
}