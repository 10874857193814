.container {
    display: flex;
    flex-direction: row;
    border-radius: var(--border-radius);
    background-color: white;
    margin: 1%;
    padding: 1%;
}

.container:hover {
    -webkit-box-shadow: 0 8px 13px 2px rgba(0, 0, 0, 0.26);
    -moz-box-shadow: 0 8px 13px 2px rgba(0, 0, 0, 0.26);
    box-shadow: 0 8px 13px 2px rgba(0, 0, 0, 0.26);
    transform: scale(1.015);
    cursor: pointer;
}