@import "../../../../_css/variables/variables.css";

.label {
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 5px;
}

.extraLabel {
    font-size: 14px;
    color: #333;
    margin-bottom: 5px;
}

.text {
    opacity: 0.9;
    font-size: 14px;
    margin-bottom: 4px;
    line-height: 16px;
}

.required {
    color: var(--color-error);
}
